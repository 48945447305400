import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
  Chip,
  TableSortLabel,
  Button,
  TextField,
  Alert,
  Avatar,
  Checkbox,
  Pagination,
} from "@mui/material";
import { ContentState, EditorState, convertFromHTML } from "draft-js";
import Select from "react-select";
import { debounce } from "lodash"; // Import debounce function from lodash

import {
  Loader,
  PopUpWindow,
  ViewArrivalForm,
  DraftEditor,
  CreateArrivalButton,
  EditArrivalForm,
  PrimaryButton,
  ViewTransaction,
} from "../../components";
import apiUrl from "../api";

import {
  CheckCircle,
  Cancel,
  Pending,
  Check,
  Close,
  RemoveRedEye,
} from "@mui/icons-material/";
import io from "socket.io-client";
import axios from "axios";

import "./TransactionTable.css";

function formatDate(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

function formatDateCalendar(date) {
  date = new Date(date);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

const getColor = (name) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 100%, 30%)`;
  return color;
};

const findContrastColor = (hexColor) => {
  const r = parseInt(hexColor.substr(1, 2), 16);
  const g = parseInt(hexColor.substr(3, 2), 16);
  const b = parseInt(hexColor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#000" : "#fff";
};

const findDueDateColor = (dueDate, selectedDay) => {
  const date = new Date(dueDate);
  const today = new Date(selectedDay);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  if (date <= today) {
    return "#00F7FF";
  } else if (date <= dayAfterTomorrow) {
    return "#FF8400";
  } else {
    return "#00FF00";
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const UserChip = ({ user, tasks, onClick, isActive }) => {
  return (
    <div className={`user-chip ${isActive ? "active" : ""}`} onClick={onClick}>
      <Avatar
        style={{
          backgroundColor: getColor(user.name),
          width: "30px",
          height: "30px",
          fontSize: "20px",
        }}
      >
        {user.name.charAt(0).toUpperCase()}
      </Avatar>
      <div>
        <p>
          {user.name} ({tasks})
        </p>
      </div>
    </div>
  );
};

const socket = io(`${apiUrl}/`);

const currencyOptions = [
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "USD",
    label: "$",
  },
  {
    value: "GBP",
    label: "£",
  },
  {
    value: "JPY",
    label: "¥",
  },
  {
    value: "RUB",
    label: "₽",
  },
];

function formatAmount(amount) {
  return amount.toFixed(2);
}
const TransactionTable = (props) => {
  const [companies, setCompanies] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState();

  //   const [selectedTransaction, setSelectedTransaction] = useState("");

  const [showPopUpAccepted, setShowPopUpAccepted] = useState(false);
  const [showPopUpAcceptedPayment, setShowPopUpAcceptedPayment] =
    useState(false);
  const [showPopUpRejected, setShowPopUpRejected] = useState(false);
  const [showPopUpView, setShowPopUpView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));

  const [transactions, setTransactions] = useState([]);

  const handleBlur = () => {
    if (amount) {
      setAmount(formatAmount(parseFloat(amount)));
    }
  };

  const handleAcceptTransaction = async (
    selectedTransaction,
    selectedCompany,
    amount
  ) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve/${selectedTransaction._id}`,
        {
          company: selectedCompany.value._id,
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };
  const handleAcceptPaymentTransaction = async (
    selectedTransaction,
    amount
  ) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve-payment/${selectedTransaction._id}`,
        {
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleAcceptSendTransaction = async (selectedTransaction, amount) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve-send-money/${selectedTransaction._id}`,
        {
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };
  const handleAcceptReceiveTransaction = async (
    selectedTransaction,
    amount
  ) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/approve-receive-money/${selectedTransaction._id}`,
        {
          amount: parseFloat(amount),
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction approved successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpAccepted(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  const handleRejectTransaction = async (selectedTransaction) => {
    try {
      const response = await axios.put(
        `${apiUrl}/transaction/reject/${selectedTransaction._id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      setSuccess("Transaction rejected successfully!");
      setError("");

      setTimeout(() => {
        setSuccess("");
        setShowPopUpRejected(false);
        setAmount(0);
      }, 1000);
    } catch (error) {
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    const fetchTransactions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/transaction?page=${page}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setTransactions(
          response.data.transactions.map((transaction) => ({
            _id: transaction._id,
            date: formatDate(transaction.transaction_date),
            amount: transaction.amount,
            currency: transaction.currency,
            reason: transaction.reason,
            payment_method: transaction.payment_method,
            status: transaction.transaction_status,
            receiver: transaction.is_receiver
              ? transaction.user
              : transaction.company
              ? transaction.company
              : "",
            sender: transaction.is_receiver
              ? transaction.company
                ? transaction.company
                : transaction.is_payment
                ? transaction.user
                : ""
              : transaction.user,
            property: transaction.property,
            notes: transaction.notes,
            is_payment: transaction.is_payment,
            is_request: transaction.is_request,
            attachments: transaction.attachments,
            created_at: transaction.created_at,
            updated_at: transaction.updated_at,
            approved_by: transaction.approved_by,
            rejected_by: transaction.rejected_by,
            approved_at: transaction.approved_at,
            rejected_at: transaction.rejected_at,
          }))
        );
        setTotalItems(response.data.totalItems);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    const fetchTransactionsByUser = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${apiUrl}/transaction/user/${props.user}?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        setTotalItems(response.data.totalItems);
        setTransactions(
          response.data.transactions.map((transaction) => ({
            _id: transaction._id,
            date: formatDate(transaction.transaction_date),
            amount: transaction.amount,
            currency: transaction.currency,
            reason: transaction.reason,
            payment_method: transaction.payment_method,
            status: transaction.transaction_status,
            receiver: transaction.is_receiver
              ? transaction.user
              : transaction.company
              ? transaction.company
              : "",
            sender: transaction.is_receiver
              ? transaction.company
                ? transaction.company
                : transaction.is_payment
                ? transaction.user
                : ""
              : transaction.user,
            property: transaction.property,
            notes: transaction.notes,
            is_payment: transaction.is_payment,
            is_request: transaction.is_request,
            attachments: transaction.attachments,
            created_at: transaction.created_at,
            updated_at: transaction.updated_at,
            approved_by: transaction.approved_by,
            rejected_by: transaction.rejected_by,
            approved_at: transaction.approved_at,
            rejected_at: transaction.rejected_at,
          }))
        );
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (props.user) {
      fetchTransactionsByUser();
    } else {
      fetchTransactions();
    }
  }, [page]);

  useEffect(() => {
    const fetchCompanies = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${apiUrl}/company`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setCompanies(response.data.companies);
        setSelectedCompany({
          value: response.data.companies[0],
          label: `${response.data.companies[0].name} | ${response.data.companies[0].taxId} | ${response.data.companies[0].balance}€ | ${response.data.companies[0].iban}`,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const handleNewTransaction = (newTransaction) => {
      setTransactions((transactions) => [
        {
          ...newTransaction,
          date: formatDate(newTransaction.transaction_date),
        },
        ...transactions,
      ]);
    };

    const handleUpdatedTransaction = (updatedTransaction) => {
      setTransactions((transactions) =>
        transactions.map((transaction) =>
          transaction._id === updatedTransaction._id
            ? {
                ...updatedTransaction,
                date: formatDate(updatedTransaction.transaction_date),
              }
            : transaction
        )
      );

      if (selectedTransaction._id === updatedTransaction._id) {
        setSelectedTransaction(
          {
            ...updatedTransaction,
            date: formatDate(updatedTransaction.transaction_date),
          }
        );
      }
      
    };

    const handleCompanyBalanceUpdate = (data) => {
      const updatedCompanies = companies.map((company) =>
        company._id === data._id
          ? { ...company, balance: data.balance }
          : company
      );
      setCompanies(updatedCompanies);

      if (selectedCompany.value._id === data._id) {
        setSelectedCompany({
          value: { ...selectedCompany.value, balance: data.balance },
          label: `${selectedCompany.value.name} | ${selectedCompany.value.taxId} | ${data.balance}€ | ${selectedCompany.value.iban}`,
        });
      }
    };

    socket.on("companyBalanceUpdate", handleCompanyBalanceUpdate);
    socket.on("newTransaction", handleNewTransaction);
    socket.on("updateTransaction", handleUpdatedTransaction);

    return () => {
      socket.off("companyBalanceUpdate", handleCompanyBalanceUpdate);
      socket.off("newTransaction", handleNewTransaction);
      socket.off("updateTransaction", handleUpdatedTransaction);
    };
  }, [companies, selectedTransaction._id, transactions]);
  return (
    <>
      {showPopUpAccepted && (selectedTransaction.is_request) && (
        <PopUpWindow
          title="Accept Transaction"
          closePopUp={() => {
            setShowPopUpAccepted(false);

            setAmount(0);
          }}
          onClose={() => {
            setShowPopUpAccepted(false);
            setAmount(0);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "50px",
              width: "100%",
            }}
          >
            <div
              className="transaction-form-amount"
              style={{
                width: amount
                  ? `${formatAmount(parseFloat(amount)).length * 25}px`
                  : "150px",
                alignSelf: "center",
              }}
            >
              <input
                type="number"
                className="transaction-form-amount__input"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onBlur={handleBlur} // Format the amount when the user unfocuses
                placeholder="00,00"
                step={0.01}
                min={0}
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                }}
              />
              <h3
                className="transaction-form-amount__currency"
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                  paddingLeft: "10px",
                }}
              >
                {currencyOptions.find(
                  (currency) => currency.value === selectedTransaction.currency
                )
                  ? currencyOptions.find(
                      (currency) =>
                        currency.value === selectedTransaction.currency
                    ).label
                  : "t"}
              </h3>
            </div>
            <Select
              options={companies.map((company) => ({
                value: company,
                label: `${company.name} | ${company.taxId} | ${company.balance} | ${company.iban}`,
              }))}
              value={selectedCompany}
              onChange={(selected) => {
                setSelectedCompany(selected);
              }}
              isClearable
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "var(--primary-color)",
                  primary25: "var(--secondary-color)",
                },
              })}
              styles={{
                container: (base) => ({
                  ...base,
                  width: "100%", // Ensure container takes 100% width
                }),
                control: (base) => ({
                  ...base,
                  width: "100%", // Ensure control takes 100% width
                }),
                indicatorSeparator: (base) => ({
                  ...base,
                  display: "none",
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected
                    ? "var(--primary-color)"
                    : base.backgroundColor,
                  color: state.isSelected ? "white" : base.color,
                }),
              }}
            />
            <PrimaryButton
              text="Accept"
              icon={<Check />}
              onClick={() => {
                handleAcceptTransaction(
                  selectedTransaction,
                  selectedCompany,
                  amount
                );
              }}
            />

            {success !== "" && (
              <div className="success-message" style={{ color: "green" }}>
                {success}
              </div>
            )}

            {error !== "" && (
              <div className="error-message" style={{ color: "red" }}>
                {error}
              </div>
            )}
          </div>
        </PopUpWindow>
      )}

      {showPopUpAccepted && (selectedTransaction.is_payment || !selectedTransaction.is_request)&& (
        <PopUpWindow
          title="Accept Transaction"
          closePopUp={() => {
            setShowPopUpAccepted(false);

            setAmount(0);
          }}
          onClose={() => {
            setShowPopUpAccepted(false);
            setAmount(0);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "50px",
              width: "100%",
            }}
          >
            <div
              className="transaction-form-amount"
              style={{
                width: amount
                  ? `${formatAmount(parseFloat(amount)).length * 25}px`
                  : "150px",
                alignSelf: "center",
              }}
            >
              <input
                type="number"
                className="transaction-form-amount__input"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                onBlur={handleBlur} // Format the amount when the user unfocuses
                placeholder="00,00"
                step={0.01}
                min={0}
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                }}
              />
              <h3
                className="transaction-form-amount__currency"
                style={{
                  width: amount
                    ? `${formatAmount(parseFloat(amount)).length * 25}px`
                    : "150px",
                  paddingLeft: "10px",
                }}
              >
                {currencyOptions.find(
                  (currency) => currency.value === selectedTransaction.currency
                )
                  ? currencyOptions.find(
                      (currency) =>
                        currency.value === selectedTransaction.currency
                    ).label
                  : "t"}
              </h3>
            </div>

            <PrimaryButton
              text="Accept"
              icon={<Check />}
              onClick={() => {
                if (selectedTransaction.is_payment) {
                  handleAcceptPaymentTransaction(selectedTransaction, amount);
                } else if (selectedTransaction.is_receiver) {
                  handleAcceptReceiveTransaction(selectedTransaction, amount);
                } else {
                  handleAcceptSendTransaction(selectedTransaction, amount);
                }
              }}
            />

            {success !== "" && (
              <div className="success-message" style={{ color: "green" }}>
                {success}
              </div>
            )}

            {error !== "" && (
              <div className="error-message" style={{ color: "red" }}>
                {error}
              </div>
            )}
          </div>
        </PopUpWindow>
      )}

      {showPopUpRejected && (
        <PopUpWindow
          title="Reject Transaction"
          closePopUp={() => {
            setShowPopUpRejected(false);
          }}
          onClose={() => {
            setShowPopUpRejected(false);
            setAmount(0);
            setError("");
            setSuccess("");
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              padding: "50px",
              width: "100%",
            }}
          >
            <Typography variant="h6" style={{ textAlign: "center" }}>
              Are you sure you want to reject this transaction?
            </Typography>

            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
              }}
            >
              <PrimaryButton
                text="Reject Transaction"
                onClick={() => {
                  handleRejectTransaction(selectedTransaction);
                }}
              />
            </div>

            {success !== "" && (
              <div className="success-message" style={{ color: "green" }}>
                {success}
              </div>
            )}

            {error !== "" && (
              <div className="error-message" style={{ color: "red" }}>
                {error}
              </div>
            )}
          </div>
        </PopUpWindow>
      )}

      {isLoading && <Loader />}

      {showPopUpView && (
        <PopUpWindow
          closePopUp={() => {
            setShowPopUpView(false);
          }}
          onClose={() => {
            setShowPopUpView(false);
          }}
        >
          <ViewTransaction
            transaction={selectedTransaction}
            onClose={() => {
              setShowPopUpView(false);
            }}
          />
        </PopUpWindow>
      )}

      {!isLoading && (
        <div className="transaction-table">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Payment Method</TableCell>

                  <TableCell>Sender</TableCell>
                  <TableCell>Receiver</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((transaction, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: "0px" }}>
                      <Tooltip title="View Transaction" placement="right">
                        <Button
                          onClick={() => {
                            setShowPopUpView(true);

                            setSelectedTransaction(transaction);
                          }}
                        >
                          <RemoveRedEye
                            sx={{ color: "var(--primary-color)" }}
                          />
                        </Button>
                      </Tooltip>

                      {transaction.status.title === "Pending" && (
                        <>
                          <Tooltip title="Accept Transaction" placement="right">
                            <Button
                              onClick={() => {
                                setShowPopUpAccepted(true);
                                setAmount(transaction.amount.toFixed(2));
                                setSelectedTransaction(transaction);
                              }}
                            >
                              <Check sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>

                          <Tooltip title="Reject Transaction" placement="right">
                            <Button
                              onClick={() => {
                                setShowPopUpRejected(true);
                                setSelectedTransaction(transaction);
                              }}
                            >
                              <Close sx={{ color: "var(--primary-color)" }} />
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={
                          transaction.status.title === "Accepted" ? (
                            <CheckCircle style={{ color: "#fff" }} />
                          ) : transaction.status.title === "Rejected" ? (
                            <Cancel style={{ color: "#fff" }} />
                          ) : (
                            <Pending style={{ color: "#fff" }} />
                          )
                        }
                        label={transaction.status.title}
                        style={{
                          backgroundColor: transaction.status.color,
                          color: findContrastColor(transaction.status.color),
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ whiteSpace: "nowrap" }}>
                      {transaction.amount.toFixed(2)}{" "}
                      {
                        currencyOptions.find(
                          (currency) => currency.value === transaction.currency
                        ).label
                      }
                    </TableCell>

                    <TableCell>{transaction.property.name}</TableCell>
                    <TableCell>{transaction.reason.title}</TableCell>
                    <TableCell>{transaction.payment_method.title}</TableCell>
                    <TableCell>
                      {transaction.sender && transaction.sender.name !== "" && (
                        <Tooltip title={transaction.sender.name}>
                          <Avatar
                            style={{
                              backgroundColor: getColor(
                                transaction.sender.name
                              ),
                              width: "30px",
                              height: "30px",
                              fontSize: "20px",
                            }}
                          >
                            {transaction.sender.name.charAt(0).toUpperCase()}
                          </Avatar>
                        </Tooltip>
                      )}
                    </TableCell>

                    <TableCell>
                      {transaction.receiver &&
                        transaction.receiver.name !== "" && (
                          <Tooltip title={transaction.receiver.name}>
                            <Avatar
                              style={{
                                backgroundColor: getColor(
                                  transaction.receiver.name
                                ),
                                width: "30px",
                                height: "30px",
                                fontSize: "20px",
                              }}
                            >
                              {transaction.receiver.name
                                .charAt(0)
                                .toUpperCase()}
                            </Avatar>
                          </Tooltip>
                        )}
                    </TableCell>
                    <TableCell>{transaction.date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={2}
            sx={{ width: "95%" }}
          >
            <Typography>
              Showing {Math.min((page - 1) * 10 + 1, totalItems)}-
              {Math.min(page * 10, totalItems)} of {totalItems} items
            </Typography>

            <Pagination
              count={Math.ceil(totalItems / 10)}
              page={page}
              color="primary"
              onChange={(event, value) => {
                setPage(value);
              }}
            />
          </Box>
        </div>
      )}
    </>
  );
};
export default TransactionTable;
